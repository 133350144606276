var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.onPublicPage
      ? _c("div", [
          _c(
            "div",
            { staticClass: "buttons mb-3" },
            [
              _vm.$featuresManager.isAvailable(_vm.$features.REPORTS_CSV)
                ? _c(
                    "b-button",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        type: "is-primary",
                        "icon-pack": "fa",
                        "icon-left": "file-csv",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.downloadCSV()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("component.report.detail_table.download_csv")
                        )
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.$featuresManager.isAvailable(_vm.$features.REPORTS_PDF)
                ? _c(
                    "b-button",
                    {
                      attrs: {
                        type: "is-primary",
                        "icon-pack": "fa",
                        "icon-left": "file-pdf",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.downloadPDF()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("component.report.detail_table.download_pdf")
                        )
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ])
      : _c("div"),
    _c("div", { staticClass: "box" }, [
      _vm.hasDataForTimerange
        ? _c("div", { staticClass: "multi-header" }, [
            _c(
              "div",
              { staticClass: "chartData" },
              [
                _vm._l(_vm.ChartServices, function (item) {
                  return [
                    _c(
                      "a",
                      {
                        key: item,
                        staticClass: "p-3 chartServices",
                        class: [
                          { "is-active": item == _vm.selectedChartData },
                          _vm.chartId,
                        ],
                        attrs: { id: item, value: item },
                        on: {
                          click: function ($event) {
                            return _vm.selectChartServices(item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("component.report.chart_data." + item))
                        ),
                      ]
                    ),
                  ]
                }),
              ],
              2
            ),
            _c(
              "div",
              {
                staticClass:
                  "is-flex is-justify-content-flex-end is-centered-mobile multi-header",
              },
              [
                _vm.isHasBoundaries
                  ? _c(
                      "div",
                      { staticClass: "field mt-1 mr-3" },
                      [
                        _c(
                          "b-switch",
                          {
                            staticClass: "chartScale",
                            class: _vm.chartId,
                            on: { input: _vm.changeChartServices },
                            model: {
                              value: _vm.isScaleByData,
                              callback: function ($$v) {
                                _vm.isScaleByData = $$v
                              },
                              expression: "isScaleByData",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("component.report.scaleByData")) +
                                " "
                            ),
                            _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  type: "is-dark",
                                  label: _vm.$t(
                                    "component.report.scaleByDataNote"
                                  ),
                                  position: "is-left",
                                  multilined: "",
                                },
                              },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "question-circle",
                                    type: "is-grey",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "is-flex" },
                  [
                    _c(
                      "div",
                      { staticClass: "buttons chart-controls mr-2 mb-3" },
                      [
                        _c("b-button", {
                          staticClass: "zoom_btn",
                          class: _vm.chartId,
                          attrs: {
                            type: "is-primary",
                            "icon-right": "plus",
                            id: "zoom_in",
                          },
                        }),
                        _c("b-button", {
                          staticClass: "zoom_btn",
                          class: _vm.chartId,
                          attrs: {
                            type: "is-primary",
                            "icon-right": "minus",
                            id: "zoom_out",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.hasDataForTimerange
                      ? _c(
                          "b-dropdown",
                          {
                            attrs: {
                              position: "is-bottom-left",
                              "append-to-body": "",
                              "aria-role": "list",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "trigger",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "component.group_dashboard.chart_options.chart_height"
                                            ),
                                            position: "is-left",
                                            type: "is-dark",
                                          },
                                        },
                                        [
                                          _c("b-button", {
                                            staticClass: "mb-2",
                                            attrs: {
                                              type: "is-primary",
                                              "icon-right": "arrows-alt-v",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1419541444
                            ),
                          },
                          [
                            _c(
                              "b-dropdown-item",
                              {
                                staticClass: "custom-dropdown-item",
                                attrs: {
                                  focusable: false,
                                  custom: "",
                                  "aria-role": "listitem",
                                },
                              },
                              [
                                _c(
                                  "b-field",
                                  {
                                    staticClass: "mb-2",
                                    attrs: {
                                      label: _vm.$t(
                                        "component.group_dashboard.chart_options.chart_height"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-radio-button",
                                      {
                                        attrs: {
                                          "native-value": _vm.ChartHeight.SMALL,
                                          type: "is-primary is-outlined",
                                          size: "is-small",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.changeChartHeight()
                                          },
                                        },
                                        model: {
                                          value: _vm.selectedChartHeight,
                                          callback: function ($$v) {
                                            _vm.selectedChartHeight = $$v
                                          },
                                          expression: "selectedChartHeight",
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon: "compress-arrows-alt",
                                          },
                                        }),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "component.group_dashboard.chart_options." +
                                                  _vm.ChartHeight.SMALL
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-radio-button",
                                      {
                                        attrs: {
                                          "native-value": _vm.ChartHeight.LARGE,
                                          type: "is-primary is-outlined",
                                          size: "is-small",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.changeChartHeight()
                                          },
                                        },
                                        model: {
                                          value: _vm.selectedChartHeight,
                                          callback: function ($$v) {
                                            _vm.selectedChartHeight = $$v
                                          },
                                          expression: "selectedChartHeight",
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: { icon: "expand-arrows-alt" },
                                        }),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "component.group_dashboard.chart_options." +
                                                  _vm.ChartHeight.LARGE
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-radio-button",
                                      {
                                        attrs: {
                                          "native-value": _vm.ChartHeight.FIT,
                                          type: "is-primary is-outlined",
                                          size: "is-small",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.changeChartHeight()
                                          },
                                        },
                                        model: {
                                          value: _vm.selectedChartHeight,
                                          callback: function ($$v) {
                                            _vm.selectedChartHeight = $$v
                                          },
                                          expression: "selectedChartHeight",
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: { icon: "expand" },
                                        }),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "component.group_dashboard.chart_options." +
                                                  _vm.ChartHeight.FIT
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-dropdown",
                      {
                        staticClass: "more-chart-filter",
                        attrs: {
                          position: "is-bottom-left",
                          "append-to-body": "",
                          "aria-role": "list",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "trigger",
                              fn: function () {
                                return [
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "component.group_dashboard.filters.more_filters"
                                        ),
                                        position: "is-left",
                                        type: "is-dark",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mb-2",
                                          staticStyle: { "font-size": "1rem" },
                                          on: { click: _vm.changeApplyButton },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              type: "is-primary",
                                              icon: "filter",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1981962101
                        ),
                      },
                      [
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "custom-dropdown-item has-text-grey",
                            attrs: { custom: "", "aria-role": "menuitem" },
                          },
                          [
                            _c("b-icon", {
                              staticClass: "mr-2",
                              attrs: { icon: "filter" },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "component.group_dashboard.filters.more_filters"
                                  )
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                        _c("hr", { staticClass: "dropdown-divider" }),
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "custom-dropdown-item",
                            attrs: {
                              focusable: false,
                              custom: "",
                              "aria-role": "listitem",
                            },
                          },
                          [
                            _c(
                              "b-field",
                              {
                                staticClass: "mb-2",
                                attrs: {
                                  label: _vm.$t(
                                    "component.group_dashboard.filters.loggers"
                                  ),
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "logger-checkboxes" },
                                  [
                                    _c("div", [
                                      _c("input", {
                                        staticClass:
                                          "mb-2 logger-checkbox allCheckboxes",
                                        class: _vm.chartId,
                                        attrs: {
                                          type: "checkbox",
                                          checked: "",
                                        },
                                        domProps: {
                                          value: _vm.$t(
                                            "component.group_dashboard.filters.allCheckboxes"
                                          ),
                                        },
                                        on: { input: _vm.changeApplyButton },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "component.group_dashboard.filters.allCheckboxes"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _vm._l(
                                      _vm.availableChartLogger,
                                      function (option) {
                                        return _c("div", { key: option.name }, [
                                          _c("div", [
                                            _c("input", {
                                              staticClass:
                                                "mb-2 logger-checkbox",
                                              class: [
                                                "checkbox-" +
                                                  _vm.Chart.normalizeSelectorName(
                                                    option.name
                                                  ),
                                                _vm.chartId,
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                checked: "",
                                                id:
                                                  "checkbox-" +
                                                  _vm.Chart.normalizeSelectorName(
                                                    option.name
                                                  ),
                                              },
                                              domProps: {
                                                value:
                                                  "checkbox-" +
                                                  _vm.Chart.normalizeSelectorName(
                                                    option.name
                                                  ),
                                              },
                                              on: {
                                                input: _vm.changeApplyButton,
                                              },
                                            }),
                                            _vm._v(
                                              " " + _vm._s(option.name) + " "
                                            ),
                                          ]),
                                        ])
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "mt-2" }, [
                              _c(
                                "div",
                                { staticClass: "buttons" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "applyLoggerFilter",
                                      class: _vm.chartId,
                                      attrs: {
                                        expanded: "",
                                        size: "is-small",
                                        type: _vm.isLoggersFilterApplied
                                          ? "is-success"
                                          : "is-primary",
                                        "icon-left": "check",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.applyLoggersFilter()
                                        },
                                      },
                                    },
                                    [
                                      _vm.isLoggersFilterApplied
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "component.group_dashboard.filters.applied"
                                                )
                                              )
                                            ),
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "component.group_dashboard.filters.apply"
                                                )
                                              )
                                            ),
                                          ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _vm._e(),
      _c("section", [
        !_vm.hasDataForTimerange
          ? _c("div", { staticClass: "empty-chart-data" }, [
              _c("p", [_vm._v(_vm._s(_vm.$t("component.report.no_data")))]),
            ])
          : _vm._e(),
        _c("div", { attrs: { id: _vm.chartId, width: "100%" } }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }