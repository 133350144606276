var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c("div", { staticClass: "content" }, [
        _c("h1", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "component.wizard.info_modal.body.tutorial.instructions.title"
                )
              ) +
              " "
          ),
        ]),
        _c("p", {
          domProps: {
            innerHTML: _vm._s(
              _vm.$t(
                "component.wizard.info_modal.body.tutorial.instructions.text"
              )
            ),
          },
        }),
        _c("h2", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("component.wizard.instructions.systemParts.title")
              ) +
              " "
          ),
        ]),
        _c("ol", [
          _c("li", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.instructions.systemParts.loggers")
              ),
            },
          }),
          _c("li", [
            _c("span", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("component.wizard.instructions.systemParts.observer")
                ),
              },
            }),
          ]),
          _c("li", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.instructions.systemParts.cloud")
              ),
            },
          }),
          _c("li", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.instructions.systemParts.app")
              ),
            },
          }),
        ]),
        _c("h2", [
          _vm._v(
            " " +
              _vm._s(_vm.$t("component.wizard.instructions.connection.title")) +
              " "
          ),
        ]),
        _c("ol", [
          _c("li", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.instructions.connection.steps.1")
              ),
            },
          }),
          _c("li", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.instructions.connection.steps.2")
              ),
            },
          }),
          _c("li", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.instructions.connection.steps.3")
              ),
            },
          }),
          _c("li", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.instructions.connection.steps.4")
              ),
            },
          }),
          _c("li", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.instructions.connection.steps.5")
              ),
            },
          }),
          _c("li", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("component.wizard.instructions.connection.steps.6")
              ),
            },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }