import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import AppConfig from '@/configLoader';
import { bootstrap } from 'vue-gtag';
import { CookieClaimManager, CookieClaimOptions } from '@/services/CookieClaimManager';
//firebase
import { initializeApp } from 'firebase/app';
import { getPerformance } from 'firebase/performance';
var cookieClaimManager;
let CustomSettingsModal = class CustomSettingsModal extends Vue {
    constructor() {
        super(...arguments);
        this.CookieClaimOptions = CookieClaimOptions;
        this.essentialCookiesSwitch = true;
        this.analyticsCookiesSwitch = false;
        this.analyticsCookiesList = [
            {
                name: '_ga',
                provider: 'Google Analytics',
                description: this.$t('cookies.custom_modal.analytics.descriptions.ga'),
                lifetime: this.$t('cookies.custom_modal.lifetime.2y'),
                type: this.$t('cookies.custom_modal.type.3rd')
            },
            {
                name: '_gid',
                provider: 'Google Analytics',
                description: this.$t('cookies.custom_modal.analytics.descriptions.ga'),
                lifetime: this.$t('cookies.custom_modal.lifetime.1d'),
                type: this.$t('cookies.custom_modal.type.3rd')
            },
            {
                name: '_gat_gtag_ APP_ID',
                provider: 'Google Analytics',
                description: this.$t('cookies.custom_modal.analytics.descriptions.ga'),
                lifetime: this.$t('cookies.custom_modal.lifetime.1m'),
                type: this.$t('cookies.custom_modal.type.3rd')
            },
            {
                name: 'SIDCC',
                provider: 'Google Analytics',
                description: this.$t('cookies.custom_modal.analytics.descriptions.SIDCC'),
                lifetime: this.$t('cookies.custom_modal.lifetime.6m'),
                type: this.$t('cookies.custom_modal.type.3rd')
            },
            {
                name: 'collect',
                provider: 'Pixel / Google Analytics',
                description: this.$t('cookies.custom_modal.analytics.descriptions.collect'),
                lifetime: this.$t('cookies.custom_modal.lifetime.session'),
                type: this.$t('cookies.custom_modal.type.3rd')
            },
            {
                name: '1P_JAR',
                provider: 'Google Analytics',
                description: this.$t('cookies.custom_modal.analytics.descriptions.1p'),
                lifetime: this.$t('cookies.custom_modal.lifetime.30d'),
                type: this.$t('cookies.custom_modal.type.3rd')
            }
        ];
        this.essentialCookiesList = [
            {
                name: 'apiUserFullName',
                provider: 'Smart Active Cloud',
                description: this.$t('cookies.custom_modal.essential.descriptions.fullName'),
                lifetime: this.$t('cookies.custom_modal.lifetime.never'),
                type: this.$t('cookies.custom_modal.type.1st')
            },
            {
                name: 'apiUserRole',
                provider: 'Smart Active Cloud',
                description: this.$t('cookies.custom_modal.essential.descriptions.role'),
                lifetime: this.$t('cookies.custom_modal.lifetime.never'),
                type: this.$t('cookies.custom_modal.type.1st')
            },
            {
                name: 'sessionPassword',
                provider: 'Smart Active Cloud',
                description: this.$t('cookies.custom_modal.essential.descriptions.pswd'),
                lifetime: this.$t('cookies.custom_modal.lifetime.never'),
                type: this.$t('cookies.custom_modal.type.1st')
            },
            {
                name: 'username',
                provider: 'Smart Active Cloud',
                description: this.$t('cookies.custom_modal.essential.descriptions.username'),
                lifetime: this.$t('cookies.custom_modal.lifetime.never'),
                type: this.$t('cookies.custom_modal.type.1st')
            }
        ];
    }
    get hasAnalyticsAllowedInConfig() {
        return AppConfig.getConfig().google_analytics.allowed == true;
    }
    get hasFirebaseAllowed() {
        return AppConfig.getConfig().firebase.allowed == true;
    }
    get firebaseConfiguration() {
        return AppConfig.getConfig().firebase.config;
    }
    created() {
        cookieClaimManager = new CookieClaimManager(this);
        this.analyticsCookiesSwitch = cookieClaimManager.hasClaim(CookieClaimOptions.ACCEPT_ANALYTICS_COOKIES);
    }
    savePreferences() {
        cookieClaimManager.addLocalClaim(CookieClaimOptions.ACCEPT_ESSENTIAL_COOKIES);
        if (this.analyticsCookiesSwitch) {
            cookieClaimManager.addLocalClaim(CookieClaimOptions.ACCEPT_ANALYTICS_COOKIES);
            if (this.hasAnalyticsAllowedInConfig == true) {
                this.enablePlugin();
            }
            if (this.hasFirebaseAllowed) {
                // Initialize Firebase
                const app = initializeApp(this.firebaseConfiguration);
                // Initialize Performance Monitoring and get a reference to the service
                const perf = getPerformance(app);
            }
        }
        else {
            if (this.hasAnalyticsAllowedInConfig == true) {
                this.$gtag.optOut();
            }
            if (this.hasFirebaseAllowed) {
                //turnoff
                const app = initializeApp(this.firebaseConfiguration);
                // Initialize Performance Monitoring and get a reference to the service
                const perf = getPerformance(app);
                perf.instrumentationEnabled = false;
                perf.dataCollectionEnabled = false;
            }
        }
        cookieClaimManager.commit();
    }
    closeModal() {
        this.savePreferences();
        this.$emit('modalClosed');
    }
    analyticsChanged() {
        this.savePreferences();
    }
    enablePlugin() {
        bootstrap().then((gtag) => {
            this.$gtag.optIn();
        });
    }
    openPolicy() {
        this.$router
            .push({
            name: 'privacyPolicy',
            params: { lang: this.$route.params.lang }
        })
            .catch((err) => err);
    }
};
CustomSettingsModal = __decorate([
    Component({})
], CustomSettingsModal);
export default CustomSettingsModal;
